//A button function in homepage#index modal to clear search input 
$(function() {
  $("#clear_search_form_input").on("click", function() {
    $("#search_word").val("");
    $("#location_from").val("");
    $("#search_word").val("");
    $("#search_distance").val("");
    $("#listing_tagparent").val("");
    $("#listing_action_tag").val("");
    $("#listing_usage_tag").val("");
    $("#listing_qualities").val("");
    $("#listing_seeds").val("");
    $("#listing_questions_4").prop("checked", false);
    $("#listing_questions_8").prop("checked", false);
    $("#listing_questions_9").prop("checked", false);
    $("#listing_questions_10").prop("checked", false);
    $("#listing_questions_11").prop("checked", false);
    $("#listing_questions_12").prop("checked", false);
  });
});

//reCAPTCHA resize
function reCAPTCHA_Sizer(){
  var selection = document.querySelector('.heading') !== null;
  if (selection) {
    var $headingWidth = document.querySelector('.heading').offsetWidth;
    var $wCap = 304; //reCAPTCHA original width
    var $hCap = 78; //reCAPTCHA original height
    var $scaleRate = 1; //scale rate
    var $scaleValue = ($headingWidth * $scaleRate)/$wCap; //reCAPTCHA scale rate
    var $h = $hCap * $scaleValue; //height
    if($headingWidth > 285) {
      $(".g-recaptcha").css("transform","scale(" + $scaleRate + ")");
      $(".g-recaptcha > div").css("height",$hCap);
    } else {
      $(".g-recaptcha").css("transform","scale(" + $scaleValue + ")");
      $(".g-recaptcha > div").css("height",$h);
    }
  }
}

window.onload = function () {
  reCAPTCHA_Sizer();
};

window.onresize = function () {
  reCAPTCHA_Sizer();
};

// For radio button to select pricing_based in listings form.
window.showPricingInputs = function () {
  const pricingBased0 = document.getElementById("listing_locations_form_listing_attributes_pricing_based_on_package");
  const pricingBased1 = document.getElementById("listing_locations_form_listing_attributes_pricing_based_on_weight");
  const pricingInputs0 = document.getElementById("selectedOnPackage");
  const pricingInputs1 = document.getElementById("selectedOnWeight");

  if (pricingBased0.checked) {
    pricingInputs0.style.display = "block";
    pricingInputs1.style.display = "none";
  } else if (pricingBased1.checked) {
    pricingInputs0.style.display = "none";
    pricingInputs1.style.display = "block";
  }
}

// For select weight_unit in listings form.
window.updateWeightUnit = function () {
  const weightUnit = document.getElementById("listing_locations_form_listing_attributes_weight_unit")
  const unitToUpdate1 = document.getElementById("unitToUpdate1")
  const unitToUpdate2 = document.getElementById("unitToUpdate2")
  if (weightUnit[0].selected) {
    unitToUpdate1.textContent = weightUnit[0].value;
    unitToUpdate2.textContent = weightUnit[0].value;
  } else if (weightUnit[1].selected) {
    unitToUpdate1.textContent = weightUnit[1].value;
    unitToUpdate2.textContent = weightUnit[1].value;
  }
}

// To change the min value of product purchase input field of seller_listing
window.updateMinOfInputField = function (fieldId) {
  const inputField = document.getElementById(fieldId);
  if (inputField.value === '0') {
    inputField.min = '0';
  } else {
    inputField.min = inputField.getAttribute("data-min");
  }
}

// Show password button function
window.showPassword = function (fieldId) {
  const showPasswordButton = document.getElementById(`show-${fieldId}`);
  const passwordField = document.getElementById(`person_${fieldId}`);

  showPasswordButton.addEventListener('click', function() {
    if (passwordField.type === 'password') {
      passwordField.type = 'text';
    } else {
      passwordField.type = 'password';
    }
  });
}

